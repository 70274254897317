var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"editar-admin-franquicia"},[(_vm.updateAdminFranquicia && this.$route.params.franquiciaId != null)?_c('vx-card',[_c('ValidationObserver',{ref:"validateFormDelegation",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('div',{staticClass:"vx-row"},[_c('div',{staticClass:"vx-col sm:w-1/2 w-full mb-3"},[_c('ValidationProvider',{attrs:{"rules":"required","name":"Nombre"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{staticClass:"w-full",attrs:{"label":"Nombre","placeholder":"Nombre"},model:{value:(_vm.updateAdminFranquicia.first_name),callback:function ($$v) {_vm.$set(_vm.updateAdminFranquicia, "first_name", $$v)},expression:"updateAdminFranquicia.first_name"}}),_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"vx-col sm:w-1/2 w-full mb-3"},[_c('ValidationProvider',{attrs:{"rules":"required","name":"Apellido"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{staticClass:"w-full",attrs:{"label":"Apellido","placeholder":"Apellido"},model:{value:(_vm.updateAdminFranquicia.last_name),callback:function ($$v) {_vm.$set(_vm.updateAdminFranquicia, "last_name", $$v)},expression:"updateAdminFranquicia.last_name"}}),_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"vx-row"},[_c('div',{staticClass:"vx-col sm:w-1/2 w-full mb-3"},[_c('ValidationProvider',{attrs:{"rules":"required|phone","name":"Telefono"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{staticClass:"w-full",attrs:{"label":"Telefono","placeholder":"Telefono"},model:{value:(_vm.updateAdminFranquicia.phone),callback:function ($$v) {_vm.$set(_vm.updateAdminFranquicia, "phone", $$v)},expression:"updateAdminFranquicia.phone"}}),_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"vx-col sm:w-1/2 w-full mb-3"},[_c('ValidationProvider',{attrs:{"rules":"required|email","name":"Email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{staticClass:"w-full",attrs:{"label":"Email","placeholder":"Email"},model:{value:(_vm.updateAdminFranquicia.email),callback:function ($$v) {_vm.$set(_vm.updateAdminFranquicia, "email", $$v)},expression:"updateAdminFranquicia.email"}}),_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"vx-col sm:w-1/2 w-full mb-3"},[_c('ValidationProvider',{attrs:{"rules":"min:8","name":"Contraseña"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{staticClass:"w-full",attrs:{"label":"Contraseña","type":"password","placeholder":"Contraseña"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"vx-col sm:w-1/2 w-full mb-3"},[_c('ValidationProvider',{attrs:{"rules":"min:8","name":"Contraseña"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{staticClass:"w-full",attrs:{"type":"password","label":"Confirmar contraseña","placeholder":"Confirmar contraseña"},model:{value:(_vm.passwordConfirmation),callback:function ($$v) {_vm.passwordConfirmation=$$v},expression:"passwordConfirmation"}}),_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"vx-row"},[_c('div',{staticClass:"vx-col w-full flex justify-end"},[_c('vs-button',{staticClass:"mr-3 mb-3",attrs:{"disabled":invalid},on:{"click":_vm.submit}},[_vm._v("Guardar")]),_c('vs-button',{staticClass:"mb-3",attrs:{"type":"border"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v("Descartar")])],1)])]}}],null,false,1325555936)})],1):_c('div',[_c('vs-alert',{attrs:{"color":"danger","title":"Ally Not Found","active.sync":"true"}},[_c('span',[_vm._v("Ally record with id: "+_vm._s(_vm.$route.params.franquiciaId)+" not found. ")]),_c('span',[_c('span',[_vm._v("Check ")]),_c('router-link',{staticClass:"text-inherit underline",attrs:{"to":{ name: 'terceros-franquicias' }}},[_vm._v("All Franquicias")])],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }