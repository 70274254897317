<template>
  <div class="editar-admin-franquicia">
    <vx-card
      v-if="updateAdminFranquicia && this.$route.params.franquiciaId != null"
    >
      <ValidationObserver ref="validateFormDelegation" v-slot="{ invalid }">
        <div class="vx-row">
          <div class="vx-col sm:w-1/2 w-full mb-3">
            <ValidationProvider
              v-slot="{ errors }"
              rules="required"
              name="Nombre"
            >
              <vs-input
                class="w-full"
                label="Nombre"
                placeholder="Nombre"
                v-model="updateAdminFranquicia.first_name"
              />
              <span class="text-danger text-sm">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
          <div class="vx-col sm:w-1/2 w-full mb-3">
            <ValidationProvider
              v-slot="{ errors }"
              rules="required"
              name="Apellido"
            >
              <vs-input
                class="w-full"
                label="Apellido"
                placeholder="Apellido"
                v-model="updateAdminFranquicia.last_name"
              />
              <span class="text-danger text-sm">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
        </div>
        <div class="vx-row">
          <div class="vx-col sm:w-1/2 w-full mb-3">
            <ValidationProvider
              v-slot="{ errors }"
              rules="required|phone"
              name="Telefono"
            >
              <vs-input
                class="w-full"
                label="Telefono"
                placeholder="Telefono"
                v-model="updateAdminFranquicia.phone"
              />
              <span class="text-danger text-sm">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
          <div class="vx-col sm:w-1/2 w-full mb-3">
            <ValidationProvider
              v-slot="{ errors }"
              rules="required|email"
              name="Email"
            >
              <vs-input
                class="w-full"
                label="Email"
                placeholder="Email"
                v-model="updateAdminFranquicia.email"
              />
              <span class="text-danger text-sm">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
          <div class="vx-col sm:w-1/2 w-full mb-3">
            <ValidationProvider
              v-slot="{ errors }"
              rules="min:8"
              name="Contraseña"
            >
              <vs-input
                class="w-full"
                label="Contraseña"
                type="password"
                placeholder="Contraseña"
                v-model="password"
              />
              <span class="text-danger text-sm">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
          <div class="vx-col sm:w-1/2 w-full mb-3">
            <ValidationProvider
              v-slot="{ errors }"
              rules="min:8"
              name="Contraseña"
            >
              <vs-input
                class="w-full"
                type="password"
                label="Confirmar contraseña"
                placeholder="Confirmar contraseña"
                v-model="passwordConfirmation"
              />
              <span class="text-danger text-sm">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
        </div>
        <div class="vx-row">
          <div class="vx-col w-full flex justify-end">
            <vs-button class="mr-3 mb-3" @click="submit" :disabled="invalid"
              >Guardar</vs-button
            >
            <vs-button type="border" class="mb-3" @click="$router.go(-1)"
              >Descartar</vs-button
            >
          </div>
        </div>
      </ValidationObserver>
    </vx-card>
    <div v-else>
      <vs-alert color="danger" title="Ally Not Found" active.sync="true">
        <span
          >Ally record with id: {{ $route.params.franquiciaId }} not found.
        </span>
        <span>
          <span>Check </span
          ><router-link
            :to="{ name: 'terceros-franquicias' }"
            class="text-inherit underline"
            >All Franquicias</router-link
          >
        </span>
      </vs-alert>
    </div>
  </div>
</template>

<script>
import { UPDATE_ADMIN_FRANCHISE } from '@/graphql/mutations';
import { ADMIN_FRANCHISE } from '@/graphql/queries';
import _ from 'lodash';
export default {
  data() {
    return {
      updateAdminFranquicia: {
        name: '',
        phone: '',
        lastName: '',
        email: '',
      },
      password: '',
      passwordConfirmation: null,
      franquiciaNotFound: false,
      routeParam: '',
    };
  },
  methods: {
    async getFranchises() {
      const { data } = await this.$apollo.query({
        query: ADMIN_FRANCHISE,
        variables: {
          id: this.$route.params.franquiciaId,
        },
      });
      this.updateAdminFranquicia = data.adminFranchise;
      this.updateAdminFranquicia = _.omit(this.updateAdminFranquicia, [
        '__typename',
      ]);

      this.franquiciaNotFound = true;
    },
    async submit() {
      if (this.password === this.passwordConfirmation && this.password != '') {
        this.updateAdminFranquicia.password = this.password;
      } else {
        if (this.password != '') {
          this.$vs.notify({
            color: 'danger',
            title: 'Contraseña no cambiada.',
            text: 'Las contraseñas no coinciden.',
          });
        }
      }
      await this.$apollo
        .mutate({
          mutation: UPDATE_ADMIN_FRANCHISE,
          variables: {
            input: this.updateAdminFranquicia,
          },
          update: (cache) => {
            try {
              const data = cache.readQuery({
                query: ADMIN_FRANCHISE,
                variables: {
                  id: this.updateAdminFranquicia.id,
                },
              });
              cache.writeQuery({
                query: ADMIN_FRANCHISE,
                data,
              });
            } catch {
              console.log();
            }
          },
        })
        .then(() => {
          this.$vs.notify({
            color: 'success',
            title: 'Admin franquicia actualizado.',
            text: 'El admin franquicia fue correctamente actualizada.',
          });
          this.$router.go(-1);
        })
        .catch(() => {
          this.$vs.notify({
            color: 'danger',
            title: 'Admin franquicia no actualizado.',
            text: 'La admin franquicia no fue correctamente actualizada.',
          });
        });
    },
  },
  mounted() {
    this.getFranchises();
  },
};
</script>

<style></style>
